@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url(./fonts/Gilroy-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Gilroy-bold";
  font-weight: 900;
  src: local("Gilroy"), url(./fonts/Gilroy-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Gilroy-medium";
  font-weight: 600;
  src: local("Gilroy"), url(./fonts/Gilroy-Medium.ttf) format("opentype");
}
/* Hide scrollbar for Chrome, Safari and Opera */
.noscrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noscrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
